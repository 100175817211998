<template>
    <button
        class="btn"
        :type="btnType"
        :class="[btnStyle, submitted]"
        :title="btnTitle"
        :disabled="loading"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
        data-is-btn
    >
        <span class="text">{{ btnTitle }}</span>
        <span class="icon">
            <IconsArrowl v-show="!loading" />
            <IconsLoading v-show="loading" />
        </span>
    </button>
</template>

<script setup>
    const props = defineProps({
        btnTitle: {
            type: String,
            required: true
        },
        btnType: {
            type: String,
            default: "button"
        },
        btnStyle: {
            type: String,
            default: "light"
        },
        loading: {
            type: Boolean,
            default: false
        }
    });

    const submitted = ref("default");

    watch(
        () => props.loading,
        (state) => {
            submitted.value = state ? "submitted" : "default";
        }
    );

    const mouseEnter = (e) => {
        if (!props.loading) {
            e.target.classList.add("active");
        }
    };

    const mouseLeave = (e) => {
        if (!props.loading) {
            e.target.style.opacity = 0;
            e.target.classList.add("inactive");
            e.target.classList.remove("active");

            setTimeout(() => {
                const currEl = e.target;
                currEl.classList.add("active");
                currEl.style.opacity = "";

                currEl.addEventListener("animationend", function onanimationend() {
                    currEl.classList.remove("active", "inactive");
                    currEl.removeEventListener("animationend", onanimationend);
                });
            }, 5);
        }
    };
</script>
